import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../utils/auth";

function AuthRoute({ component: Component, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.getToken() &&
		auth.getToken() !== "undefined"  &&
		auth.getToken() !== null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default AuthRoute;
