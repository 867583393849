import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Button from "./Button";

const Popup = ({
  display,
  title,
  text,
  textStyle,
  firstBtnLabel,
  firstBtnStyle = "",
  firstBtnClick,
  firstDisabled = false,
  secondBtnLabel,
  secondBtnStyle = "",
  secondBtnClick,
  popupType,
  handleClose,
  ...props
}) => {
  // useEffect(() => {
  //   document.body.style.cssText = `overflow-y: scroll`;
  //   document.getElementById("root").style.cssText =
  //     "height: 100vh; overflow:hidden";

  //   if (popupType === "inPopup") {
  //   } else {
  //     return () => {
  //       document.body.style.cssText = `overflow-y: "auto"`;
  //       document.getElementById("root").style.cssText = "";
  //     };
  //   }
  // }, [popupType]);
  return (
    <PopupArea displayType={display}>
      <PopupWra>
        {title && <PopupTitle>{title}</PopupTitle>}
        {text && <PopupText>{text}</PopupText>}
        {props.children}
        <ButtonArea>
          <Button
            styleClass={`height50 ` + firstBtnStyle}
            onClick={firstBtnClick}
            disabled={firstDisabled}
          >
            {firstBtnLabel}
          </Button>
          {secondBtnLabel && (
            <Button
              styleClass={`height50 margin_top ` + secondBtnStyle}
              onClick={secondBtnClick}
            >
              {secondBtnLabel}
            </Button>
          )}
        </ButtonArea>
      </PopupWra>
      <PopupBg onClick={() => (handleClose ? handleClose() : undefined)} />
    </PopupArea>
  );
};
const ButtonArea = styled.div`
  margin-top: 30px;
`;
const PopupText = styled.p`
  font-size: 14px;
  font-weight: 300;
  line-height: 1.71;
  color: #3a3a3a;
`;

const PopupTitle = styled.h1`
  font-size: 26px;
  font-weight: 700;
  color: #0c0c0c;
  margin-bottom: 25px;
  @media (max-width: 720px) {
    font-size: 24px;
    margin-bottom: 19px;
  }
`;
const PopupWra = styled.div`
  padding: 40px 35px;
  border-radius: 10px;
  background: #fff;
  position: absolute;
  width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 720px) {
    width: 320px;
    padding: 30px 20px;
  }
`;
const PopupBg = styled.div`
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);
`;
const PopupArea = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  z-index: 1001;

  ${({ displayType }) =>
    displayType &&
    css`
      display: flex;
    `}
`;

export default Popup;
