import loadable from "@loadable/component";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
export const Root = loadable(() => import("./Root"));
export const Login = loadable(() => import("./user/Login"));
export const Nonmember = loadable(() => import("./user/Nonmember"));
export const NonmemberCancel = loadable(() => import("./user/NonmemberCancel"));
export const NonmemberReturn = loadable(() => import("./user/NonmemberReturn"));

export const Join = loadable(() => import("./user/Join"));
export const SnsJoin = loadable(() => import("./user/SnsJoin"));
export const Term = loadable(() => import("./user/Term"));
export const FindId = loadable(() => import("./user/FindId"));
export const FindPw = loadable(() => import("./user/FindPw"));
//애플유저 전용 회원가입
export const AppleLogin = loadable(() => import("./user/AppleLogin"));
export const AppleJoin = loadable(() => import("./user/AppleJoin"));

//모바일 결제시, rediredct
export const MobilePayment = loadable(() => import("./user/MobilePayment"));

export const Cart = loadable(() => import("./shop/Cart"));
export const ShopLogin = loadable(() => import("./shop/ShopLogin"));
export const ShopInfo = loadable(() => import("./shop/ShopInfo"));
export const ShopDetail = loadable(() => import("./shop/ShopDetail"));
export const Search = loadable(() => import("./shop/Search"));
export const ProductOrder = loadable(() => import("./shop/ProductOrder"));
export const ProductCartOrder = loadable(() =>
  import("./shop/ProductCartOrder")
);

export const Exercise = loadable(() => import("./community/Exercise"));
export const CommunityRegister = loadable(() =>
  import("./community/CommunityRegister")
);
export const CommunityEdit = loadable(() =>
  import("./community/CommunityEdit")
);
export const ChallengeEdit = loadable(() =>
  import("./community/ChallengeEdit")
);
export const Notice = loadable(() => import("./community/Notice"));
export const NoticeDetail = loadable(() => import("./community/NoticeDetail"));
export const CalendarPage = loadable(() => import("./community/CalendarPage"));
export const Free = loadable(() => import("./community/Free"));

export const Jspace = loadable(() => import("./community/Jspace"));
export const JspaceDetail = loadable(() => import("./community/JspaceDetail"));
export const Challenge = loadable(() => import("./community/Challenge"));
export const ChallengeRegister = loadable(() =>
  import("./community/ChallengeRegister")
);
export const Jellin = loadable(() => import("./community/Jellin"));

export const Lab = loadable(() => import("./class/Lab"));
export const ClassIndex = loadable(() => import("./class/ClassIndex"));
export const Introduce = loadable(() => import("./class/Introduce"));

export const Program = loadable(() => import("./class/Program"));
export const ProgramOrder = loadable(() => import("./class/ProgramOrder"));
export const ProgramDetail = loadable(() => import("./class/ProgramDetail"));
export const MyClass = loadable(() => import("./class/MyClass"));
export const ClassPlay = loadable(() => import("./class/ClassPlay"));
export const XmlPage = loadable(() => import("./class/XmlPage"));
export const OfflineClass = loadable(() => import("./class/OfflineClass"));
export const ClassQnaRegister = loadable(() =>
  import("./class/ClassQnaRegister")
);

export const Mypage = loadable(() => import("./mypage/Mypage"));
export const NonmemberOrder = loadable(() => import("./user/NonmemberOrder"));
export const LevelIntro = loadable(() => import("./mypage/LevelIntro"));
export const EditProfile = loadable(() => import("./mypage/EditProfile"));
export const MyPoint = loadable(() => import("./mypage/MyPoint"));
export const MyProgram = loadable(() => import("./mypage/MyProgram"));
export const MyAlarm = loadable(() => import("./mypage/MyAlarm"));
export const MyFaq = loadable(() => import("./mypage/MyFaq"));
export const QnaRegister = loadable(() => import("./mypage/QnaRegister"));
export const QnaDetail = loadable(() => import("./mypage/QnaDetail"));
export const MyOrder = loadable(() => import("./mypage/MyOrder"));
export const MyPost = loadable(() => import("./mypage/MyPost"));
export const MyComment = loadable(() => import("./mypage/MyComment"));
export const MyOrderDetail = loadable(() => import("./mypage/MyOrderDetail"));
export const MyOrderReturn = loadable(() => import("./mypage/MyOrderReturn"));
export const MyOrderCancel = loadable(() => import("./mypage/MyOrderCancel"));
export const Test = loadable(() => import("./class/Test"));
