import React from "react";
import { Route } from "react-router-dom";
import { useHistory } from "react-router";
import useSWR from "swr";
import Popup from "./Popup";

const ClassRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();

  const { data: check } = useSWR("/class/check");

  return (
    <Route
      {...rest}
      render={(props) =>
        check && check?.success ? (
          <Component {...props} />
        ) : (
          <>
            <Component {...props} />
            {check && (
              <Popup
                display={check}
                title="클래스 이용 회원 전용"
                text={
                  <>
                    클래스를 이용중인
                    <br />
                    회원만 이용 가능합니다.
                  </>
                }
                firstBtnLabel="클래스 보러가기"
                firstBtnClick={() => history.push("/class")}
                secondBtnLabel="확인"
                secondBtnStyle="blackline"
                secondBtnClick={() => history.goBack()}
              />
            )}
          </>
        )
      }
    />
  );
};

export default ClassRoute;
