import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import App from "./client/App";

import "./styles/reset.css";
import "./styles/index.css";
import "./styles/font.css";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { RecoilRoot } from "recoil";

Sentry.init({
  dsn: "https://a2d8cabce69747e7bcd17cb55cdb2882@o4503975195574272.ingest.sentry.io/4504450820145152",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>,
  document.getElementById("root")
);
