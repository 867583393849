import React from "react";
import styled from "@emotion/styled";

const Button = ({ onClick, buttonDisabled, ...props }) => {
  return (
    <Btn
      className={props.styleClass}
      onClick={onClick}
      disabled={buttonDisabled}
      {...props}
    >
      {props.children}
    </Btn>
  );
};

const Btn = styled.button`
  width: 100%;
  height: 60px;
  font-weight: 700;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  background: #3a3a3a;
  color: #fff;

  &.blackline {
    background: transparent;
    border: 1px solid #3a3a3a;
    color: #3a3a3a;
  }
  &.black {
    background: #0c0c0c;
  }
  &.gray {
    border: 1px solid #979595;
    color: #5c5a5a;
    font-weight: 500;
    background: #fff;
  }

  &:disabled {
    background: #979595;
    border: 1px solid #979595;
    opacity: 0.5;
  }

  &.margin_top {
    margin-top: 10px;
  }

  &.height50 {
    height: 50px;
  }
  &.height54 {
    height: 54px;
  }

  &.medium {
    font-weight: 500;
  }
  &.phone_btn {
    max-width: 100px;
    min-width: 100px;
    margin-left: 10px;
    height: 54px;
  }

  &.community {
    height: 35px;
  }

  @media (max-width: 720px) {
    font-size: 14px;
    height: 46px;
    &.height50 {
      height: 40px;
    }
    &.margin_top {
      margin-top: 5px;
    }
    &.height54 {
      height: 48px;
      &.community {
        height: 31px;
      }
    }
    &.phone_btn {
      max-width: 76px;
      min-width: 76px;
      margin-left: 8px;
      height: 48px;
    }
  }
`;

export default Button;
